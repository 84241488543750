<template>
  <v-form
      ref="form"
      v-model="valid"
      lazy-validation
  >
    <!-- 検索エリア -->
    <v-container>
      <v-row class="mt-2">
        <v-col>
          <h2>{{ surveyType.filter((e) => (e.value == targetSurveyType))[0].text }} 回答データチェック</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="auto" sm="auto" md="auto" lg="1">
          <YearPicker
            label="対象年度"
            v-model="searchCondition.targetYear"
            format="YYYY年"
            selectType="month"
            :clearable="false"
            :hideDetails="true"
            suffix="度"
          ></YearPicker>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="3" sm="3" md="2" lg="2">
          <v-btn class="" color="primary" width="150" :disabled="loading" @click="cancel()">キャンセル</v-btn>
        </v-col>
        <v-col cols="3" sm="3" md="2" lg="2">
          <v-btn class="" color="primary" width="150" :disabled="loading" @click="registData()">回答データ保存</v-btn>
        </v-col>
        <v-col cols="3" sm="3" md="2" lg="2">
          <v-btn class="" color="primary" width="150" :disabled="loading" @click="goToSummary()">集計</v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-row>
        <v-col class="pt-0 pb-0 mb-n2">件数 {{answerDataCount}}件</v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12">
          <v-data-table
            :headers="headers"
            :items="answerDataList"
            :loading="loading"
            :dense='true'
            :items-per-page="10"
            :mobile-breakpoint="0"
            :disable-pagination='true'
            :hide-default-header='false'
            :hide-default-footer='true'
            :fixed-header='true'
            :disable-sort='false'
            locale="ja-jp"
            loading-text="読込中"
            no-data-text="データがありません。"
            class="table-border no-hover-effect"
            height="calc(100vh - 250px)"
          >
            <template v-slot:body="{items}">
              <tbody>
                <tr v-for="(item) in items" :key="item.memberNo+'_'+item.targetMonth" :class="['tr-data', (item.disable == 1) ? 'disable-color' : 'enable']" >
                  <td :class="['center', (item.disable == 1) ? 'disable-color' : '']">
                    <input type='checkbox' v-model="item.disable" :true-value="1" :false-value="0" />
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : '']">
                    {{item.checkCount}}
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : '']">
                    {{item.memberNo}}
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : '']">
                    {{item.companyName}}
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : '']">
                    {{item.officeName}}
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : '']">
                    {{item.prefCode}}
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : '']">
                    {{item.pref}}
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : '']">
                    <CvTextField v-model="item.volume" :rules="[]" propClass="right-input" ></CvTextField>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : '']">
                    <CvTextField v-model="item.iceVolume" :rules="[]" propClass="right-input" ></CvTextField>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : '']">
                    <CvTextField v-model="item.frozenVolume" :rules="[]" propClass="right-input" ></CvTextField>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : '']">
                    <!-- <CvTextField v-model="item.targetMonth" :rules="[]" ></CvTextField> -->
                    <DatePicker
                      v-model="item.targetMonth"
                      format="YYYY/MM"
                      selectType="month"
                      propClass="td-data-picker"
                    ></DatePicker>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : '']">
                    <CvTextField v-if="item.newSupplier" v-model="item.newSupplierName" :rules="[]" ></CvTextField>
                    <v-select v-else
                      :items="electricityElecCoName"
                      v-model="item.supplier"
                      :rules="[]"
                    >
                    </v-select>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : '']">
                    <CvTextField v-model="item.plan" :rules="[]" propClass="left-input" ></CvTextField>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : '']">
                    <CvTextField v-model="item.contract" :rules="[]" propClass="right-input" ></CvTextField>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : '']">
                    <CvTextField v-model="item.energyUse" :rules="[]" propClass="right-input" ></CvTextField>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : '']">
                    <CvTextField v-model="item.energyStorage" :rules="[]" propClass="right-input" ></CvTextField>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : '']">
                    <CvTextField v-model="item.energyCold" :rules="[]" propClass="right-input" ></CvTextField>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : '']">
                    <CvTextField v-model="item.energyOffice" :rules="[]" propClass="right-input" ></CvTextField>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : '']">
                    <CvTextField v-model="item.energyIce" :rules="[]" propClass="right-input" ></CvTextField>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : '']">
                    <CvTextField v-model="item.energyFrozen" :rules="[]" propClass="right-input" ></CvTextField>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : '']">
                    <CvTextField v-model="item.energyOther" :rules="[]" propClass="right-input" ></CvTextField>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : '']">
                    <CvTextField v-model="item.costBase" :rules="[]" propClass="right-input" ></CvTextField>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : '']">
                    <CvTextField v-model="item.costEnergy" :rules="[]" propClass="right-input" ></CvTextField>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : '']">
                    <CvTextField v-model="item.costFuel" :rules="[]" propClass="right-input" ></CvTextField>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : '']">
                    <CvTextField v-model="item.costReEnergy" :rules="[]" propClass="right-input" ></CvTextField>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : '']">
                    <CvTextField v-model="item.costOther" :rules="[]" propClass="right-input" ></CvTextField>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : '']">
                    <CvTextField v-model="item.costStorage" :rules="[]" propClass="right-input" ></CvTextField>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : '']">
                    <CvTextField v-model="item.discountOther1" :rules="[]" propClass="right-input" ></CvTextField>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : '']">
                    <CvTextField v-model="item.discountOther2" :rules="[]" propClass="right-input" ></CvTextField>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : '']">
                    <CvTextField v-model="item.discountOther3" :rules="[]" propClass="right-input" ></CvTextField>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : '']">
                    <CvTextField v-model="item.payment" :rules="[]" propClass="right-input" ></CvTextField>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : '']">
                    <v-select
                      :items="electricityDutyFree"
                      v-model="item.exemption"
                      :rules="[]"
                    >
                    </v-select>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : '']">
                    <v-select
                      :items="[
                        { text: '1:10大電力', value: 1 },
                        { text: '2:新電力', value: 2 },
                        { text: '3:併用', value: 3 },
                        { text: '4:他10大電力', value: 4 },
                      ]"
                      v-model="item.type"
                      :rules="[]"
                    >
                    </v-select>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-col>
      </v-row>


    </v-container>
  </v-form>
</template>

<script>
import Debug from '../Lib/Debug'
import DatePicker from "./common/DatePicker.vue";
import YearPicker from "./common/YearPicker.vue";
import CvTextField from './common/Cv_text_field.vue'
import Const from '../mixins/const';

export default {
  mixins: [Const],
  
  components: {
    DatePicker,
    YearPicker,
    CvTextField,
  },

  data: () => ({
    valid : true,
    loading: false,

    show: true,

    // 調査種別リスト
    surveyType: [
      {text: "月次報告データ", value: 1},
      {text: "実態調査", value: 2},
      {text: "電力調査", value: 3},
      {text: "冷媒調査", value: 4},
    ],

    // 対象調査種別
    targetSurveyType: null,

    // 検索条件
    searchCondition: {
      targetYear: "2022",
      ansPeriodFrom: "",
      ansPeriodTo: "",
    },

    // テーブルヘッダー
    headers: [
        { text: '除外', align: 'center', sortable: true, value: 'disable', width: 60 },
        { text: 'NG判定', align: 'center', sortable: true, value: 'checkCount', width: 60 },
        { text: '会員番号', align: 'center', sortable: true, value: 'memberNo', width: 80 },
        { text: '会社名', align: 'center', sortable: true, value: 'companyName', width: 150 },
        { text: '事業所名', align: 'center', sortable: true, value: 'officeName', width: 150 },
        { text: '府県 コード', align: 'center', sortable: true, value: 'prefCode', width: 60 },
        { text: '府県名', align: 'center', sortable: true, value: 'pref', width: 80 },
        { text: '所管容積', align: 'center', sortable: true, value: 'volume', width: 120 },
        { text: '製氷生産ﾄﾝ/年', align: 'center', sortable: true, value: 'iceVolume', width: 120 },
        { text: '凍結生産ﾄﾝ/年', align: 'center', sortable: true, value: 'frozenVolume', width: 120 },
        { text: '年月', align: 'center', sortable: true, value: 'targetMonth', width: 120 },
        { text: '電力会社名', align: 'center', sortable: true, value: 'supplier', width: 300 },
        { text: '契約種別', align: 'center', sortable: true, value: 'plan', width: 300 },
        { text: '契約電力kw', align: 'center', sortable: true, value: 'contract', width: 120 },
        { text: '使用電力量kwh', align: 'center', sortable: true, value: 'energyUse', width: 120 },
        { text: '蓄熱電力量kwh', align: 'center', sortable: true, value: 'energyStorage', width: 120 },
        { text: '冷蔵kwh', align: 'center', sortable: true, value: 'energyCold', width: 120 },
        { text: '事務棟kwh', align: 'center', sortable: true, value: 'energyOffice', width: 120 },
        { text: '製氷kwh', align: 'center', sortable: true, value: 'energyIce', width: 120 },
        { text: '凍結kwh', align: 'center', sortable: true, value: 'energyFrozen', width: 120 },
        { text: 'その他kwh', align: 'center', sortable: true, value: 'energyOther', width: 120 },
        { text: '基本料金', align: 'center', sortable: true, value: 'costBase', width: 120 },
        { text: '電力量料金', align: 'center', sortable: true, value: 'costEnergy', width: 120 },
        { text: '燃料費調整額', align: 'center', sortable: true, value: 'costFuel', width: 120 },
        { text: '再エネ賦課金等', align: 'center', sortable: true, value: 'costReEnergy', width: 120 },
        { text: 'その他', align: 'center', sortable: true, value: 'costOther', width: 120 },
        { text: '産業用蓄熱調整契約', align: 'center', sortable: true, value: 'costStorage', width: 120 },
        { text: 'その他割引1', align: 'center', sortable: true, value: 'discountOther1', width: 120 },
        { text: 'その他割引2', align: 'center', sortable: true, value: 'discountOther2', width: 120 },
        { text: 'その他割引3', align: 'center', sortable: true, value: 'discountOther3', width: 120 },
        { text: '支払電力料金合計', align: 'center', sortable: true, value: 'payment', width: 120 },
        { text: '賦課金減免有無', align: 'center', sortable: true, value: 'exemption', width: 120 },
        { text: '区分', align: 'center', sortable: true, value: 'type', width: 140 },
    ],

    // 回答データ一覧
    answerDataList: [
      { 
        disable: 0, checkCount: 0, 
        memberNo: "010550", companyName: "○○冷蔵", officeName: "喜茂別物流センター", prefCode: '01', pref: "北海道", 
        volume: '154865', iceVolume: '123', frozenVolume: '987', targetMonth: '2022-08-01', newSupplier: 1, supplier: null, newSupplierName : 'ENEOS株式会社',
        plan: '高圧A', contract: '321', energyUse: '4615', energyStorage: '4896', energyCold: '34646', energyOffice: '4486', energyIce: '64135', energyFrozen: '476662', energyOther: '56325',
        costBase: '1466', costEnergy: '8946', costFuel: '8652', costReEnergy: '1631', costOther: '485', costStorage: '4966',
        discountOther1: '615', discountOther2: '8433', discountOther3: '3214', payment: '485987', exemption: 0,
        type: 3,
      },
      { 
        disable: 0, checkCount: 0, 
        memberNo: "010550", companyName: "○○冷蔵", officeName: "喜茂別物流センター", prefCode: '01', pref: "北海道", 
        volume: '154865', iceVolume: '123', frozenVolume: '987', targetMonth: '2022-09-01', newSupplier: 0, supplier: 1, newSupplierName : '',
        plan: '高圧A', contract: '321', energyUse: '4615', energyStorage: '4896', energyCold: '34646', energyOffice: '4486', energyIce: '64135', energyFrozen: '476662', energyOther: '56325',
        costBase: '1466', costEnergy: '8946', costFuel: '8652', costReEnergy: '1631', costOther: '485', costStorage: '4966',
        discountOther1: '615', discountOther2: '8433', discountOther3: '3214', payment: '485987', exemption: 0,
        type: 3,
      },
      { 
        disable: 0, checkCount: 0, 
        memberNo: "010550", companyName: "○○冷蔵", officeName: "喜茂別物流センター", prefCode: '01', pref: "北海道", 
        volume: '154865', iceVolume: '123', frozenVolume: '987', targetMonth: '2022-10-01', newSupplier: 0, supplier: 1, newSupplierName : '',
        plan: '高圧A', contract: '321', energyUse: '4615', energyStorage: '4896', energyCold: '34646', energyOffice: '4486', energyIce: '64135', energyFrozen: '476662', energyOther: '56325',
        costBase: '1466', costEnergy: '8946', costFuel: '8652', costReEnergy: '1631', costOther: '485', costStorage: '4966',
        discountOther1: '615', discountOther2: '8433', discountOther3: '3214', payment: '485987', exemption: 0,
        type: 3,
      },
      { 
        disable: 0, checkCount: 0, 
        memberNo: "010550", companyName: "○○冷蔵", officeName: "喜茂別物流センター", prefCode: '01', pref: "北海道", 
        volume: '154865', iceVolume: '123', frozenVolume: '987', targetMonth: '2022-11-01', newSupplier: 0, supplier: 1, newSupplierName : '',
        plan: '高圧A', contract: '321', energyUse: '4615', energyStorage: '4896', energyCold: '34646', energyOffice: '4486', energyIce: '64135', energyFrozen: '476662', energyOther: '56325',
        costBase: '1466', costEnergy: '8946', costFuel: '8652', costReEnergy: '1631', costOther: '485', costStorage: '4966',
        discountOther1: '615', discountOther2: '8433', discountOther3: '3214', payment: '485987', exemption: 0,
        type: 3,
      },
      { 
        disable: 0, checkCount: 0, 
        memberNo: "010551", companyName: "○○冷蔵", officeName: "喜茂別物流センター", prefCode: '01', pref: "北海道", 
        volume: '154865', iceVolume: '123', frozenVolume: '987', targetMonth: '2022-08-01', newSupplier: 0, supplier: 1, newSupplierName : '',
        plan: '高圧A', contract: '321', energyUse: '4615', energyStorage: '4896', energyCold: '34646', energyOffice: '4486', energyIce: '64135', energyFrozen: '476662', energyOther: '56325',
        costBase: '1466', costEnergy: '8946', costFuel: '8652', costReEnergy: '1631', costOther: '485', costStorage: '4966',
        discountOther1: '615', discountOther2: '8433', discountOther3: '3214', payment: '485987', exemption: 0,
        type: 1,
      },
      { 
        disable: 0, checkCount: 0, 
        memberNo: "010552", companyName: "○○冷蔵", officeName: "喜茂別物流センター", prefCode: '01', pref: "北海道", 
        volume: '154865', iceVolume: '123', frozenVolume: '987', targetMonth: '2022-08-01', newSupplier: 1, supplier: null, newSupplierName : 'テプコカスタマーサービス株式会社',
        plan: '高圧A', contract: '321', energyUse: '4615', energyStorage: '4896', energyCold: '34646', energyOffice: '4486', energyIce: '64135', energyFrozen: '476662', energyOther: '56325',
        costBase: '1466', costEnergy: '8946', costFuel: '8652', costReEnergy: '1631', costOther: '485', costStorage: '4966',
        discountOther1: '615', discountOther2: '8433', discountOther3: '3214', payment: '485987', exemption: 0,
        type: 2,
      },
      { 
        disable: 0, checkCount: 0, 
        memberNo: "010553", companyName: "○○冷蔵", officeName: "喜茂別物流センター", prefCode: '01', pref: "北海道", 
        volume: '154865', iceVolume: '123', frozenVolume: '987', targetMonth: '2022-08-01', newSupplier: 1, supplier: null, newSupplierName : 'テプコカスタマーサービス株式会社',
        plan: '産業用電力B 産業用季時別電力B', contract: '321', energyUse: '4615', energyStorage: '4896', energyCold: '34646', energyOffice: '4486', energyIce: '64135', energyFrozen: '476662', energyOther: '56325',
        costBase: '1466', costEnergy: '8946', costFuel: '8652', costReEnergy: '1631', costOther: '485', costStorage: '4966',
        discountOther1: '615', discountOther2: '8433', discountOther3: '3214', payment: '485987', exemption: 0,
        type: 2,
      },
      { 
        disable: 0, checkCount: 0, 
        memberNo: "010554", companyName: "○○冷蔵", officeName: "喜茂別物流センター", prefCode: '01', pref: "北海道", 
        volume: '154865', iceVolume: '123', frozenVolume: '987', targetMonth: '2022-08-01', newSupplier: 1, supplier: null, newSupplierName : 'テプコカスタマーサービス株式会社',
        plan: '産業用高圧BS1型標準(季節別)', contract: '321', energyUse: '4615', energyStorage: '4896', energyCold: '34646', energyOffice: '4486', energyIce: '64135', energyFrozen: '476662', energyOther: '56325',
        costBase: '1466', costEnergy: '8946', costFuel: '8652', costReEnergy: '1631', costOther: '485', costStorage: '4966',
        discountOther1: '615', discountOther2: '8433', discountOther3: '3214', payment: '485987', exemption: 0,
        type: 2,
      },
      { 
        disable: 0, checkCount: 0, 
        memberNo: "010555", companyName: "○○冷蔵", officeName: "喜茂別物流センター", prefCode: '01', pref: "北海道", 
        volume: '154865', iceVolume: '123', frozenVolume: '987', targetMonth: '2022-08-01', newSupplier: 1, supplier: null, newSupplierName : 'テプコカスタマーサービス株式会社',
        plan: '高圧A', contract: '321', energyUse: '4615', energyStorage: '4896', energyCold: '34646', energyOffice: '4486', energyIce: '64135', energyFrozen: '476662', energyOther: '56325',
        costBase: '1466', costEnergy: '8946', costFuel: '8652', costReEnergy: '1631', costOther: '485', costStorage: '4966',
        discountOther1: '615', discountOther2: '8433', discountOther3: '3214', payment: '485987', exemption: 0,
        type: 2,
      },
      { 
        disable: 0, checkCount: 0, 
        memberNo: "010555", companyName: "○○冷蔵", officeName: "喜茂別物流センター", prefCode: '01', pref: "北海道", 
        volume: '154865', iceVolume: '123', frozenVolume: '987', targetMonth: '2022-09-01', newSupplier: 1, supplier: null, newSupplierName : 'テプコカスタマーサービス株式会社',
        plan: '高圧A', contract: '321', energyUse: '4615', energyStorage: '4896', energyCold: '34646', energyOffice: '4486', energyIce: '64135', energyFrozen: '476662', energyOther: '56325',
        costBase: '1466', costEnergy: '8946', costFuel: '8652', costReEnergy: '1631', costOther: '485', costStorage: '4966',
        discountOther1: '615', discountOther2: '8433', discountOther3: '3214', payment: '485987', exemption: 0,
        type: 2,
      },
      { 
        disable: 0, checkCount: 0, 
        memberNo: "010555", companyName: "○○冷蔵", officeName: "喜茂別物流センター", prefCode: '01', pref: "北海道", 
        volume: '154865', iceVolume: '123', frozenVolume: '987', targetMonth: '2022-10-01', newSupplier: 1, supplier: null, newSupplierName : 'テプコカスタマーサービス株式会社',
        plan: '高圧B', contract: '321', energyUse: '4615', energyStorage: '4896', energyCold: '34646', energyOffice: '4486', energyIce: '64135', energyFrozen: '476662', energyOther: '56325',
        costBase: '1466', costEnergy: '8946', costFuel: '8652', costReEnergy: '1631', costOther: '485', costStorage: '4966',
        discountOther1: '615', discountOther2: '8433', discountOther3: '3214', payment: '485987', exemption: 0,
        type: 2,
      },
      { 
        disable: 0, checkCount: 0, 
        memberNo: "010556", companyName: "○○冷蔵", officeName: "喜茂別物流センター", prefCode: '01', pref: "北海道", 
        volume: '154865', iceVolume: '123', frozenVolume: '987', targetMonth: '2022-08-01', newSupplier: 1, supplier: null, newSupplierName : 'テプコカスタマーサービス株式会社',
        plan: '高圧A', contract: '321', energyUse: '4615', energyStorage: '4896', energyCold: '34646', energyOffice: '4486', energyIce: '64135', energyFrozen: '476662', energyOther: '56325',
        costBase: '1466', costEnergy: '8946', costFuel: '8652', costReEnergy: '1631', costOther: '485', costStorage: '4966',
        discountOther1: '615', discountOther2: '8433', discountOther3: '3214', payment: '485987', exemption: 0,
        type: 2,
      },
    ],
    answerDataCount: 0,

  }),

  // ライフサイクルフック
  created: function () {
    this.targetSurveyType = this.$store.getters.getTargetSurveyType;

    
  },

  mounted: async function () {
    this.loadList();
    
  },

  methods: {
    /** 表示更新 */
    loadList() {
      Debug.log('function[loadList]');
      this.loading = true;
      this.$refs.form.validate();
      this.answerDataCount = this.answerDataList.length;
      this.loading = false;
    },

    // キャンセル
    cancel() {
      if (!confirm("変更をキャンセルします。よろしいですか？")) return;
      this.$router.back();
    },

    // 保存
    registData() {
      if (!confirm("回答データを保存します。よろしいですか？")) return;
      alert("保存しました。");
    },

    // 集計
    goToSummary() {
      if (!confirm("回答データを保存し、集計画面に移動します。よろしいですか？")) return;
      alert("保存しました。集計画面に移動します。");
      this.$router.push({name: 'SurveySummary'});
    },
  },

  watch: {
  },

  computed :{
  }
};
</script>

<style scoped>

.disablecolor {
  background: #bbbbbb;
}

/** テキストフィールド */
.v-text-field {
    padding-top: 6px;
}
.v-input >>> .v-input__slot {
    margin-bottom: 2px;
}

/* ホバー効果を無効化 */
.no-hover-effect >>> tr:hover {
  background-color: transparent !important;
}

/** テーブルの縦横固定 */
.v-data-table >>> th, td {
  padding-left: 10px !important;
  padding-right: 10px !important;
  overflow-wrap: break-word;
  word-break: keep-all;
}
.v-data-table >>> td {
  height: 40px !important;
}
.v-data-table >>> td > div{
  height: 60px !important;
}
.v-data-table--fixed-header >>> th:nth-child(1) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    top: 0;
    z-index: 3;
}
.v-data-table--fixed-header >>> .tr-data td:nth-child(1) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    z-index: 2;
}
.v-data-table--fixed-header >>> .tr-data.enable td:nth-child(1) {
    background: #ffffff;
}
.v-data-table--fixed-header >>> th:nth-child(2) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 60px;
    top: 0;
    z-index: 3;
}
.v-data-table--fixed-header >>> .tr-data td:nth-child(2) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 60px;
    z-index: 2;
}
.v-data-table--fixed-header >>> .tr-data.enable td:nth-child(2) {
    background: #ffffff;
}
.v-data-table--fixed-header >>> th:nth-child(3) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 120px;
    top: 0;
    z-index: 3;
}
.v-data-table--fixed-header >>> .tr-data td:nth-child(3) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 120px;
    z-index: 2;
}
.v-data-table--fixed-header >>> .tr-data.enable td:nth-child(3) {
    background: #ffffff;
}
.v-data-table--fixed-header >>> th:nth-child(4) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 200px;
    top: 0;
    z-index: 3;
}
.v-data-table--fixed-header >>> .tr-data td:nth-child(4) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 200px;
    z-index: 2;
}
.v-data-table--fixed-header >>> .tr-data.enable td:nth-child(4) {
    background: #ffffff;
}
.v-data-table--fixed-header >>> th:nth-child(5) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 350px;
    top: 0;
    z-index: 3;
}
.v-data-table--fixed-header >>> .tr-data td:nth-child(5) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 350px;
    z-index: 2;
}
.v-data-table--fixed-header >>> .tr-data.enable td:nth-child(5) {
    background: #ffffff;
}

</style>
