import Axios from "axios";
import token from "./setToken";

export default {
  // async sumNichireisou(targetMonth, targetPref) {
  //   let params = new URLSearchParams();
  //   params.append("targetMonth", JSON.stringify(targetMonth));
  //   params.append("targetPref", JSON.stringify(targetPref));

  //   try {
  //     // await token.checkToken();
  //     const res = await Axios.post("/api/CityDataAssociationSumNichireisou", params, token.setTokenToHeader());
  //     return res;
  //   } catch (error) {
  //     console.error("日冷倉協報告を作成できません[sumNichireisou]");
  //     throw error;
  //   }
  // },

  async sumAssociationDB(associationId, targetMonth) {
    let params = new URLSearchParams();
    params.append("associationId", associationId);
    params.append("targetMonth", targetMonth);

    try {
      // await token.checkToken();
      //const res = await Axios.post("/api/CityDataAssociationSumAssociationDB", params, token.setTokenToHeader());
      const res = await Axios.post("/api2/CreateCityDataAssocDb", params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("冷協DBを作成できません[sumAssociationDB]");
      throw error;
    }
  },

  async get12cityDB(targetMonth, targetCity, sumTargetMonth) {
    let params = new URLSearchParams();
    params.append("targetCity", JSON.stringify(targetCity));
    params.append("targetMonth", JSON.stringify(targetMonth));
    params.append("sumTargetMonth", JSON.stringify(sumTargetMonth));

    try {
      // await token.checkToken();
      const res = await Axios.post("/api/CityData12cityDB", params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("12都市DBを作成できません[get12cityDB]");
      throw error;
    }
  },

  /** 都道府県(12都市)集計 個別データ更新 */
  async setDataThisPrev(thisAnswer, prevAnswer) {
    let params = new URLSearchParams();
    params.append("thisAnswer", JSON.stringify(thisAnswer));
    params.append("prevAnswer", JSON.stringify(prevAnswer));

    try {
      // await token.checkToken();
      const res = await Axios.post("/api/CityDataSumThisPrev", params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("月次報告データ都道府県集計を設定できません[setDataThisPrev]");
      throw error;
    }
  },

  /** 府県別集計 個別データ更新 */
  async setDataThisPrevPrefSum(thisAnswer, prevAnswer) {
    let params = new URLSearchParams();
    params.append("thisAnswer", JSON.stringify(thisAnswer));
    params.append("prevAnswer", JSON.stringify(prevAnswer));

    try {
      // await token.checkToken();
      const res = await Axios.post("/api/CityDataSumThisPrevPrefSum", params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("月次報告データ府県別集計を設定できません[setDataThisPrevPrefSum]");
      throw error;
    }
  },

  /** 運輸局報告 作成 */
  async getTransportBureau(associationId, targetAssociationId, targetPrefId, targetMonth) {
    let params = new URLSearchParams();
    params.append('associationId', associationId);
    params.append('targetAssociationId', targetAssociationId);
    params.append('targetPrefId', targetPrefId);
    params.append('targetMonth', targetMonth);
 
    try {
      const res = await Axios.post("/api2/CreateCityDataReport/TransportBureau", params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("運輸局報告を作成できません[getMlitOfficeData]");
      throw error;
    }
  },

  /** 日冷倉協月次報告 作成 */
  async getReportNichireisou(associationId, targetAssociationId, targetPrefId, targetMonth) {
    let params = new URLSearchParams();
    params.append('associationId', associationId);
    params.append('targetAssociationId', targetAssociationId);
    params.append('targetPrefId', targetPrefId);
    params.append('targetMonth', targetMonth);
 
    try {
      const res = await Axios.post("/api2/CreateCityDataReport/ReportNichireisou", params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("日冷倉協月次報告を作成できません[getReportNichireisou]");
      throw error;
    }
  },

  /** 集計分析 作成 */
  async getBunseki(associationId, targetAssociationId, targetPrefId, targetMonth) {
    let params = new URLSearchParams();
    params.append('associationId', associationId);
    params.append('targetAssociationId', targetAssociationId);
    params.append('targetPrefId', targetPrefId);
    params.append('targetMonth', targetMonth);
 
    try {
      const res = await Axios.post("/api2/CreateCityDataBunseki/Bunseki", params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("集計分析を作成できません[getBunseki]");
      throw error;
    }
  },

  /** 府県別集計 作成 */
  async getPrefSum(innerSelectedList, targetMonth) {
    let params = new URLSearchParams();
    params.append('innerSelectedList', innerSelectedList);
    params.append('targetMonth', targetMonth);
 
    try {
      const res = await Axios.post("/api2/CreateCityDataPrefSum", params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("府県別集計を作成できません[getPrefSum]");
      throw error;
    }
  },
};
