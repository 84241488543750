
<template>
    <div>
        <v-text-field
            v-model="innerValue"
            @change="save()"
            :rules="rules"
            :class="propClass"
            :label="label" 
            :dense="dense"
            :maxlength="maxlength"
            :loading="loading"
            :readonly="readonly"
            :hide-details="hideDetails"
        ></v-text-field>
    </div>
</template>

<script>
export default {
    model: {
        prop: 'value',
        event: 'input'
    },

    props: {
        value: String,
        rules: Array,
        propClass: String,
        label: String,
        dense: Boolean,
        maxlength: Number,
        loading: Boolean,
        readonly: Boolean,
        hideDetails: {
            type: Boolean,
            default: false
        },
    },

    data() {
        return {
            innerValue: null,
        }
    },

    methods: {
        save() {
            // 親コンポーネント（のv-model）に値を渡す
            this.$emit("input", this.innerValue);
        },
    },

    created : function() {
        // v-modelから読み込む
        this.innerValue = this.value;
    },
}
</script>

<style scoped>
.v-text-field {
    padding-top: 6px;
}
.v-input >>> .v-input__slot {
    margin-bottom: 2px;
}
</style>