<template>
  <v-form
      ref="form"
      v-model="valid"
      lazy-validation
  >
    <!-- 検索エリア -->
    <v-container>
      <v-row class="mt-2">
        <v-col>
          <h2>{{ surveyType.filter((e) => (e.value == targetSurveyType))[0].text }} 回答データチェック</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="auto" sm="auto" md="auto" lg="1">
          <YearPicker
            label="対象年度"
            v-model="searchCondition.targetYear"
            format="YYYY年"
            selectType="month"
            :clearable="false"
            :hideDetails="true"
            suffix="度"
          ></YearPicker>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="3" sm="3" md="2" lg="2">
          <v-btn class="" color="primary" width="150" :disabled="loading" @click="cancel()">キャンセル</v-btn>
        </v-col>
        <v-col cols="3" sm="3" md="2" lg="2">
          <v-btn class="" color="primary" width="150" :disabled="loading" @click="registData()">回答データ保存</v-btn>
        </v-col>
        <v-col cols="3" sm="3" md="2" lg="2">
          <v-btn class="" color="primary" width="150" :disabled="loading" @click="goToSummary()">集計</v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-row>
        <v-col class="pt-0 pb-0 mb-n2">件数 {{answerDataCount}}件</v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12">
          <v-data-table
            :headers="headers"
            :items="answerDataList"
            :loading="loading"
            :dense='true'
            :items-per-page="10"
            :mobile-breakpoint="0"
            :disable-pagination='true'
            :hide-default-header='false'
            :hide-default-footer='true'
            :fixed-header='true'
            :disable-sort='false'
            locale="ja-jp"
            loading-text="読込中"
            no-data-text="データがありません。"
            class="table-border no-hover-effect"
            height="calc(100vh - 250px)"
          >
            <template v-slot:body="{items}">
              <!-- <thead>
                <tr class="header-row">
                  <th class="center" v-for="(header, i) in headers" :key="i" :style="'width:'+header.width+'px'">
                    {{header.text}}
                  </th>
                </tr>
              </thead> -->
              <tbody>
                <tr v-for="(item) in items" :key="item.memberNo" :class="['tr-data', (item.disable == 1) ? 'disable-color' : 'enable']" >
                  <td :class="['center', (item.disable == 1) ? 'disable-color' : '']">
                    <input type='checkbox' v-model="item.disable" :true-value="1" :false-value="0" />
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : '']">
                    {{item.checkCount}}
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : '']">
                    <v-select
                      :items="refrigerantUsage"
                      v-model="item.usage"
                      :rules="[]"
                    >
                    </v-select>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : '']">
                    {{item.memberNo}}
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : '']">
                    {{item.companyName}}
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : '']">
                    {{item.officeName}}
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : '']">
                    {{item.prefCode}}
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : '']">
                    {{item.pref}}
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : '']">
                    <CvTextField v-model="item.volume" :rules="[]" propClass="right-input" ></CvTextField>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : '']">
                    <v-select
                      :items="refrigerantMethod"
                      v-model="item.method"
                      :rules="[]"
                    >
                    </v-select>
                  </td>
                  <!-- <td :class="[(item.disable == 1) ? 'disable-color' : '']">
                    <v-select
                      :items="[
                        { text: "アンモニア", value: 0 },
                        { text: "CO2", value: 1 },
                        { text: "R22", value: 2 },
                        { text: "R502", value: 3 },
                        { text: "R12", value: 4 },
                        { text: "R23", value: 5 },
                        { text: "R404A", value: 6 },
                        { text: "R407C", value: 7 },
                        { text: "R410A", value: 8 },
                        { text: "空気", value: 9 },
                        { text: "その他", value: 10 },
                      ]"
                      v-model="item.singleName"
                      :rules="[]"
                    >
                    </v-select>
                  </td> -->
                  <td :class="[(item.disable == 1) ? 'disable-color' : '']">
                    <CvTextField v-model="item.singleVolume" :rules="[]" propClass="right-input" ></CvTextField>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : '']">
                    <CvTextField v-model="item.singleInit" :rules="[]" propClass="right-input" ></CvTextField>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : '']">
                    <CvTextField v-model="item.singleYear" :rules="[]" propClass="right-input" ></CvTextField>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : '']">
                    <CvTextField v-model="item.singlePrice" :rules="[]" propClass="right-input" ></CvTextField>
                  </td>
                  <!-- <td :class="[(item.disable == 1) ? 'disable-color' : '']">
                    <v-select
                      :items="[
                        { text: "アンモニア", value: 0 },
                        { text: "CO2", value: 1 },
                        { text: "R22", value: 2 },
                        { text: "R502", value: 3 },
                        { text: "R12", value: 4 },
                        { text: "R23", value: 5 },
                        { text: "R404A", value: 6 },
                        { text: "R407C", value: 7 },
                        { text: "R410A", value: 8 },
                        { text: "空気", value: 9 },
                        { text: "その他", value: 10 },
                      ]"
                      v-model="item.dualName"
                      :rules="[]"
                    >
                    </v-select>
                  </td> -->
                  <td :class="[(item.disable == 1) ? 'disable-color' : '']">
                    <CvTextField v-model="item.dualVolume" :rules="[]" propClass="right-input" ></CvTextField>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : '']">
                    <CvTextField v-model="item.dualInit" :rules="[]" propClass="right-input" ></CvTextField>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : '']">
                    <CvTextField v-model="item.dualYear" :rules="[]" propClass="right-input" ></CvTextField>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : '']">
                    <CvTextField v-model="item.dualPrice" :rules="[]" propClass="right-input" ></CvTextField>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : '']">
                    <v-select
                      :items="refrigerantWorker"
                      v-model="item.worker"
                      :rules="[]"
                    >
                    </v-select>
                  </td>
                  <td :class="['left', (item.disable == 1) ? 'disable-color' : '']">
                    {{item.remarks}}
                  </td>
                </tr>
              </tbody>
            </template>

            <!-- <template v-slot:item="{ item }">
              <tr :key="item.memberNo">
                <td class="center">
                  {{item.memberNo}}
                </td>
                <td class="left">
                  {{item.companyName}}
                </td>
                <td class="left">
                  {{item.officeName}}
                </td>
                <td class="left">
                  {{item.pref}}
                </td>
                <td class="center">
                  {{item.ansStatus1 == 0 ? '' : '○'}}
                </td>
                <td class="center">
                  {{item.ansStatus2 == 0 ? '' : '○'}}
                </td>
                <td class="center">
                  {{item.ansStatus3 == 0 ? '' : '○'}}
                </td>
              </tr>
            </template> -->
          </v-data-table>
        </v-col>
      </v-row>


    </v-container>
  </v-form>
</template>

<script>
import Debug from '../Lib/Debug'
import YearPicker from "./common/YearPicker.vue";
import CvTextField from './common/Cv_text_field.vue'
import Const from '../mixins/const';

export default {
  mixins: [Const],
  
  components: {
    YearPicker,
    CvTextField,
  },

  data: () => ({
    valid : true,
    loading: false,

    show: true,

    // 調査種別リスト
    surveyType: [
      {text: "月次報告データ", value: 1},
      {text: "実態調査", value: 2},
      {text: "電力調査", value: 3},
      {text: "冷媒調査", value: 4},
    ],

    // 対象調査種別
    targetSurveyType: null,

    // 検索条件
    searchCondition: {
      targetYear: "2022",
      ansPeriodFrom: "",
      ansPeriodTo: "",
    },

    // テーブルヘッダー
    headers: [
        { text: '除外', align: 'center', sortable: true, value: 'disable', width: 60 },
        { text: 'NG判定', align: 'center', sortable: true, value: 'checkCount', width: 60 },
        { text: '区分', align: 'center', sortable: true, value: 'usage', width: 160 },
        { text: '会員番号', align: 'center', sortable: true, value: 'memberNo', width: 80 },
        { text: '会社名', align: 'center', sortable: true, value: 'companyName', width: 150 },
        { text: '事業所名', align: 'center', sortable: true, value: 'officeName', width: 150 },
        { text: '府県 コード', align: 'center', sortable: true, value: 'prefCode', width: 60 },
        { text: '府県名', align: 'center', sortable: true, value: 'pref', width: 80 },
        { text: '所管容積', align: 'center', sortable: true, value: 'volume', width: 120 },
        { text: '冷却方式', align: 'center', sortable: true, value: 'method', width: 120 },
        { text: '1元 冷媒名称', align: 'center', sortable: true, value: 'singleName', width: 140 },
        { text: '1元 対象所管容積', align: 'center', sortable: true, value: 'singleVolume', width: 120 },
        { text: '1元 初期充填量', align: 'center', sortable: true, value: 'singleInit', width: 120 },
        { text: '1元 年間補充量', align: 'center', sortable: true, value: 'singleYear', width: 120 },
        { text: '1元 購入価格', align: 'center', sortable: true, value: 'singlePrice', width: 120 },
        { text: '2元 冷媒名称', align: 'center', sortable: true, value: 'dualName', width: 140 },
        { text: '2元 対象所管容積', align: 'center', sortable: true, value: 'dualVolume', width: 120 },
        { text: '2元 初期充填量', align: 'center', sortable: true, value: 'dualInit', width: 120 },
        { text: '2元 年間補充量', align: 'center', sortable: true, value: 'dualYear', width: 120 },
        { text: '2元 購入価格', align: 'center', sortable: true, value: 'dualPrice', width: 120 },
        { text: '充填方法', align: 'center', sortable: true, value: 'worker', width: 140 },
        { text: '備考', align: 'center', sortable: true, value: 'remarks', width: 200 },
    ],

    // 回答データ一覧
    answerDataList: [
      { 
        disable: 0, checkCount: 0, 
        usage: 0, 
        memberNo: "010550", companyName: "○○冷蔵", officeName: "喜茂別物流センター", prefCode: '01', pref: "北海道", 
        volume: '154865', method: 0,
        singleName: 0, singleVolume: '63742', singleInit: '63742', singleYear: '5', singlePrice: '155', 
        dualName: 2, dualVolume: '63742', dualInit: '63742', dualYear: '5', dualPrice: '155', 
        worker: 0, remarks: '備考テキスト'
      },
      { 
        disable: 0, checkCount: 0, 
        usage: 0, 
        memberNo: "010551", companyName: "○○冷蔵", officeName: "喜茂別物流センター", prefCode: '01', pref: "北海道", 
        volume: '154865', method: 0,
        singleName: 3, singleVolume: '63742', singleInit: '63742', singleYear: '5', singlePrice: '155', 
        dualName: 1, dualVolume: '63742', dualInit: '63742', dualYear: '5', dualPrice: '155', 
        worker: 1, remarks: '備考テキスト'
      },
      { 
        disable: 0, checkCount: 0, 
        usage: 0, 
        memberNo: "010552", companyName: "○○冷蔵", officeName: "喜茂別物流センター", prefCode: '01', pref: "北海道", 
        volume: '154865', method: 0,
        singleName: 9, singleVolume: '63742', singleInit: '63742', singleYear: '5', singlePrice: '155', 
        dualName: 2, dualVolume: '63742', dualInit: '63742', dualYear: '5', dualPrice: '155', 
        worker: 2, remarks: '備考テキスト'
      },
      { 
        disable: 0, checkCount: 0, 
        usage: 0, 
        memberNo: "010553", companyName: "○○冷蔵", officeName: "喜茂別物流センター", prefCode: '01', pref: "北海道", 
        volume: '154865', method: 0,
        singleName: 2, singleVolume: '63742', singleInit: '63742', singleYear: '5', singlePrice: '155', 
        dualName: 1, dualVolume: '63742', dualInit: '63742', dualYear: '5', dualPrice: '155', 
        worker: 0, remarks: '備考テキスト'
      },
      { 
        disable: 0, checkCount: 0, 
        usage: 0, 
        memberNo: "010554", companyName: "○○冷蔵", officeName: "喜茂別物流センター", prefCode: '01', pref: "北海道", 
        volume: '154865', method: 0,
        singleName: 7, singleVolume: '63742', singleInit: '63742', singleYear: '5', singlePrice: '155', 
        dualName: 3, dualVolume: '63742', dualInit: '63742', dualYear: '5', dualPrice: '155', 
        worker: 0, remarks: '備考テキスト'
      },
      { 
        disable: 0, checkCount: 0, 
        usage: 0, 
        memberNo: "010555", companyName: "○○冷蔵", officeName: "喜茂別物流センター", prefCode: '01', pref: "北海道", 
        volume: '154865', method: 0,
        singleName: 1, singleVolume: '63742', singleInit: '63742', singleYear: '5', singlePrice: '155', 
        dualName: 2, dualVolume: '63742', dualInit: '63742', dualYear: '5', dualPrice: '155', 
        worker: 0, remarks: '備考テキスト'
      },
      { 
        disable: 0, checkCount: 0, 
        usage: 0, 
        memberNo: "010556", companyName: "○○冷蔵", officeName: "喜茂別物流センター", prefCode: '01', pref: "北海道", 
        volume: '154865', method: 0,
        singleName: 2, singleVolume: '63742', singleInit: '63742', singleYear: '5', singlePrice: '155', 
        dualName: 1, dualVolume: '63742', dualInit: '63742', dualYear: '5', dualPrice: '155', 
        worker: 0, remarks: '備考テキスト'
      },
      { 
        disable: 0, checkCount: 0, 
        usage: 0, 
        memberNo: "010557", companyName: "○○冷蔵", officeName: "喜茂別物流センター", prefCode: '01', pref: "北海道", 
        volume: '154865', method: 0,
        singleName: 6, singleVolume: '63742', singleInit: '63742', singleYear: '5', singlePrice: '155', 
        dualName: 10, dualVolume: '63742', dualInit: '63742', dualYear: '5', dualPrice: '155', 
        worker: 0, remarks: '備考テキスト'
      },
      { 
        disable: 0, checkCount: 0, 
        usage: 0, 
        memberNo: "010558", companyName: "○○冷蔵", officeName: "喜茂別物流センター", prefCode: '01', pref: "北海道", 
        volume: '154865', method: 0,
        singleName: 5, singleVolume: '63742', singleInit: '63742', singleYear: '5', singlePrice: '155', 
        dualName: 2, dualVolume: '63742', dualInit: '63742', dualYear: '5', dualPrice: '155', 
        worker: 0, remarks: '備考テキスト'
      },
      { 
        disable: 0, checkCount: 0, 
        usage: 0, 
        memberNo: "010559", companyName: "○○冷蔵", officeName: "喜茂別物流センター", prefCode: '01', pref: "北海道", 
        volume: '154865', method: 0,
        singleName: 3, singleVolume: '63742', singleInit: '63742', singleYear: '5', singlePrice: '155', 
        dualName: 1, dualVolume: '63742', dualInit: '63742', dualYear: '5', dualPrice: '155', 
        worker: 0, remarks: '備考テキスト'
      },
      { 
        disable: 0, checkCount: 0, 
        usage: 0, 
        memberNo: "010560", companyName: "○○冷蔵", officeName: "喜茂別物流センター", prefCode: '01', pref: "北海道", 
        volume: '154865', method: 0,
        singleName: 2, singleVolume: '63742', singleInit: '63742', singleYear: '5', singlePrice: '155', 
        dualName: 3, dualVolume: '63742', dualInit: '63742', dualYear: '5', dualPrice: '155', 
        worker: 0, remarks: '備考テキスト'
      },
      { 
        disable: 0, checkCount: 0, 
        usage: 0, 
        memberNo: "010561", companyName: "○○冷蔵", officeName: "喜茂別物流センター", prefCode: '01', pref: "北海道", 
        volume: '154865', method: 0,
        singleName: 1, singleVolume: '63742', singleInit: '63742', singleYear: '5', singlePrice: '155', 
        dualName: 2, dualVolume: '63742', dualInit: '63742', dualYear: '5', dualPrice: '155', 
        worker: 0, remarks: '備考テキスト'
      },
    ],
    answerDataCount: 0,

  }),

  // ライフサイクルフック
  created: function () {
    this.targetSurveyType = this.$store.getters.getTargetSurveyType;

    
  },

  mounted: async function () {
    this.loadList();
    
  },

  methods: {
    /** 表示更新 */
    loadList() {
      Debug.log('function[loadList]');
      this.loading = true;
      this.$refs.form.validate();
      this.answerDataCount = this.answerDataList.length;
      this.loading = false;
    },

    // キャンセル
    cancel() {
      if (!confirm("変更をキャンセルします。よろしいですか？")) return;
      this.$router.back();
    },

    // 保存
    registData() {
      if (!confirm("回答データを保存します。よろしいですか？")) return;
      alert("保存しました。");
    },

    // 集計
    goToSummary() {
      if (!confirm("回答データを保存し、集計画面に移動します。よろしいですか？")) return;
      alert("保存しました。集計画面に移動します。");
      this.$router.push({name: 'SurveySummary'});
    },
  },

  watch: {
  },

  computed :{
  }
};
</script>

<style scoped>

.disablecolor {
  background: #bbbbbb;
}

/** テキストフィールド */
.v-text-field {
    padding-top: 6px;
}
.v-input >>> .v-input__slot {
    margin-bottom: 2px;
}

/* ホバー効果を無効化 */
.no-hover-effect >>> tr:hover {
  background-color: transparent !important;
}

/** テーブルの縦横固定 */
.v-data-table >>> th, td {
  padding-left: 10px !important;
  padding-right: 10px !important;
  overflow-wrap: break-word;
  word-break: keep-all;
}
.v-data-table >>> td {
  height: 40px !important;
}
.v-data-table >>> td > div{
  height: 60px !important;
}
.v-data-table--fixed-header >>> th:nth-child(1) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    top: 0;
    z-index: 3;
}
.v-data-table--fixed-header >>> .tr-data td:nth-child(1) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    z-index: 2;
}
.v-data-table--fixed-header >>> .tr-data.enable td:nth-child(1) {
    background: #ffffff;
}
.v-data-table--fixed-header >>> th:nth-child(2) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 60px;
    top: 0;
    z-index: 3;
}
.v-data-table--fixed-header >>> .tr-data td:nth-child(2) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 60px;
    z-index: 2;
}
.v-data-table--fixed-header >>> .tr-data.enable td:nth-child(2) {
    background: #ffffff;
}
.v-data-table--fixed-header >>> th:nth-child(3) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 120px;
    top: 0;
    z-index: 3;
}
.v-data-table--fixed-header >>> .tr-data td:nth-child(3) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 120px;
    z-index: 2;
}
.v-data-table--fixed-header >>> .tr-data.enable td:nth-child(3) {
    background: #ffffff;
}
.v-data-table--fixed-header >>> th:nth-child(4) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 280px;
    top: 0;
    z-index: 3;
}
.v-data-table--fixed-header >>> .tr-data td:nth-child(4) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 280px;
    z-index: 2;
}
.v-data-table--fixed-header >>> .tr-data.enable td:nth-child(4) {
    background: #ffffff;
}
.v-data-table--fixed-header >>> th:nth-child(5) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 360px;
    top: 0;
    z-index: 3;
}
.v-data-table--fixed-header >>> .tr-data td:nth-child(5) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 360px;
    z-index: 2;
}
.v-data-table--fixed-header >>> .tr-data.enable td:nth-child(5) {
    background: #ffffff;
}
.v-data-table--fixed-header >>> th:nth-child(6) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 510px;
    top: 0;
    z-index: 3;
}
.v-data-table--fixed-header >>> .tr-data td:nth-child(6) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 510px;
    z-index: 2;
}
.v-data-table--fixed-header >>> .tr-data.enable td:nth-child(6) {
    background: #ffffff;
}

</style>
