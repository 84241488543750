import Axios from "axios";
import token from "./setToken";

export default {
  async getData(surveyId, memberNo) {
    try {
      // await token.checkToken();
      const res = await Axios.get("/api/FactFindingAnswer/" + surveyId + '/' + memberNo, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("実態調査回答を取得できません[getData]");
      throw error;
    }
  },  

  async setFactFindingAnswerData(factFindingAnswerData, qaList, mode) {

    let params = new URLSearchParams();
    params.append('factFindingAnswerData', JSON.stringify(factFindingAnswerData));
    params.append('qaList', JSON.stringify(qaList));
    params.append('mode', mode);

    try {
      // await token.checkToken();
      const res = await Axios.post('/api/FactFindingAnswer', params, token.setTokenToHeader());
      return res;

    } catch (error) {
      console.error("実態調査回答を設定できません");
      throw error;
    }
  }, 

  /** 回答データチェック 一覧取得 */
  async getSurveyAnsCheckDataList(searchCondition) {
    let params = new URLSearchParams();
    params.append('searchCondition', JSON.stringify(searchCondition));

    try {
      // await token.checkToken();
      const res = await Axios.post("/api/FactFindingAnsCheckDataList", params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("実態調査データを取得できません[getSurveyAnsCheckDataList]");
      throw error;
    }
  },

  /** 回答データチェック 回答データ保存 */
  async setDataList(factFindingAnswerDataList) {

    let params = new URLSearchParams();
    params.append('factFindingAnswerDataList', JSON.stringify(factFindingAnswerDataList));

    try {
      // await token.checkToken();
      const res = await Axios.post('/api/FactFindingAnsSetDataList', params, token.setTokenToHeader());
      return res;

    } catch (error) {
      console.error("実態調査回答を設定できません");
      throw error;
    }
  }, 

};
